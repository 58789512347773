exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-equipe-tsx": () => import("./../../../src/pages/equipe.tsx" /* webpackChunkName: "component---src-pages-equipe-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-fonctionnalites-tsx": () => import("./../../../src/pages/fonctionnalites.tsx" /* webpackChunkName: "component---src-pages-fonctionnalites-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-landing-1-tsx": () => import("./../../../src/pages/landing-1.tsx" /* webpackChunkName: "component---src-pages-landing-1-tsx" */),
  "component---src-pages-landing-2-tsx": () => import("./../../../src/pages/landing-2.tsx" /* webpackChunkName: "component---src-pages-landing-2-tsx" */),
  "component---src-pages-mentions-legales-tsx": () => import("./../../../src/pages/mentions-legales.tsx" /* webpackChunkName: "component---src-pages-mentions-legales-tsx" */),
  "component---src-pages-politique-confidentialite-tsx": () => import("./../../../src/pages/politique-confidentialite.tsx" /* webpackChunkName: "component---src-pages-politique-confidentialite-tsx" */),
  "component---src-pages-tarifs-tsx": () => import("./../../../src/pages/tarifs.tsx" /* webpackChunkName: "component---src-pages-tarifs-tsx" */)
}

